import React from "react";
import { Card, Tag, Icon } from "@blueprintjs/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faAmazon,
    faBitcoin,
    faReact,
    faHtml5,
    faSass,
    faNodeJs,
    faNpm,
    faAws,
    faJsSquare,
} from "@fortawesome/free-brands-svg-icons";

import { faChargingStation } from "@fortawesome/free-solid-svg-icons";

const Bitcoin = <FontAwesomeIcon icon={faBitcoin} size="3x" />;
const Amazon = <FontAwesomeIcon icon={faAmazon} size="3x" />;
const EV = <FontAwesomeIcon icon={faChargingStation} size="3x" />;

const ReactI = <FontAwesomeIcon icon={faReact} size="2x" />;
const HTML = <FontAwesomeIcon icon={faHtml5} size="2x" />;
const SCSS = <FontAwesomeIcon icon={faSass} size="2x" />;

const Node = <FontAwesomeIcon icon={faNodeJs} size="2x" />;
const NPM = <FontAwesomeIcon icon={faNpm} size="2x" />;

const AWS = <FontAwesomeIcon icon={faAws} size="2x" />;
const JS = <FontAwesomeIcon icon={faJsSquare} size="2x" />;

function Showcase() {
    return (
        <>
            <h2>Personal Projects</h2>
            <div className="showcase">
                <Card
                    interactive={true}
                    onClick={() => {
                        window.open("https://transcendent-cucurucho-7f595f.netlify.app/");
                    }}
                >
                    <Icon icon={Bitcoin}></Icon>
                    <h3>Cryptocurrency Rankings</h3>
                    <p>
                        Having gained an interest in the world of blockchain and crypto during the initial boom in late
                        2017, I decided to invest in some of the technologies during their early stages. Over time some
                        have bloomed and others reduced to less than a penny, but regardless the concept itself is still
                        in it's infancy and who knows what the future holds. I decided to create a list of the Top 20
                        ranked coins in the world using <code>React</code> with hooks, and the{" "}
                        <a
                            id="link"
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://docs.coincap.io/?version=latest"
                        >
                            CoinCap API
                        </a>{" "}
                        for real time data.
                    </p>
                    <Tag round icon={ReactI}>
                        React
                    </Tag>
                    <Tag round icon={HTML}>
                        HTML
                    </Tag>
                    <Tag round icon={SCSS}>
                        SCSS
                    </Tag>
                </Card>
                <Card
                    interactive={true}
                    onClick={() => {
                        window.open("http://18.132.53.197/");
                    }}
                >
                    <Icon icon={EV}></Icon>
                    <h3>Project EVA</h3>
                    <p>
                        I wanted to challenge myself by processing, manipulating and displaying a large common dataset.
                        Having a personal interest in electric vehicles helped me come up with a large data source for
                        this full stack project. Project EVA (Electric Vehicle Articles) is a web application driven by
                        a large electric vehicle dataset of over 9000 documents.
                    </p>
                    <Tag round icon={ReactI}>
                        React
                    </Tag>
                    <Tag round icon={Node}>
                        Node.js
                    </Tag>
                    <Tag round icon={NPM}>
                        NPM
                    </Tag>
                </Card>
                <Card
                    interactive={true}
                    onClick={() => {
                        window.open("https://github.com/jawsh/alexa-daily-facts");
                    }}
                >
                    <Icon icon={Amazon}></Icon>
                    <h3>Alexa Facts Skill</h3>
                    <p>
                        AWS offers a plethora of fantastic services that are extremely useful for creating software
                        today. I wanted to get involved on a personal project and since I own an Amazon Echo, I created
                        an Alexa skill. The skill itself is very basic and simply iterates a basic array of facts that I
                        found. I was more interested in the opportunity to get my hands on an AWS service and to learn
                        how serverless technology works. This was my first interaction with AWS Lambda.
                    </p>
                    <Tag round icon={AWS}>
                        Lambda
                    </Tag>
                    <Tag round icon={JS}>
                        JavaScript
                    </Tag>
                </Card>
            </div>
        </>
    );
}

export default Showcase;
