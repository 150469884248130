import React, { useEffect } from "react";
import Typed from "typed.js";

const Typer = () => {
    let element;

    useEffect(() => {
        const options = {
            strings: [
                "Software Engineer",
                "Football Fan",
                "Dog Lover",
                "Video Gamer",
                '<a target="_blank" id="time" href="https://en.wikipedia.org/wiki/You_(Time_Person_of_the_Year)">Time Person of the Year 2006</a>^1000',
                "Full Stack Developer",
                "Technology Enthusiast",
            ],
            typeSpeed: 50,
            backSpeed: 40,
            loop: true,
            autoInsertCss: true,
            shuffle: true,
            startDelay: 500,
        };

        new Typed(element, options);
    }, [element]);
    return (
        <div className={"type-wrap"}>
            <h1>Josh Williams</h1>
            <code>
                <span
                    className="typer"
                    ref={(el) => {
                        element = el;
                    }}
                ></span>
            </code>
        </div>
    );
};

export default Typer;
