import React from "react";
import { Navbar, AnchorButton, Alignment } from "@blueprintjs/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGithub, faLinkedin } from "@fortawesome/free-brands-svg-icons";

const LinkedIn = <FontAwesomeIcon icon={faLinkedin} />;
const Github = <FontAwesomeIcon icon={faGithub} />;

const Navigation = () => {
    return (
        <Navbar fixedToTop>
            <Navbar.Group align={Alignment.LEFT}>
                <Navbar.Heading>Developer Profile</Navbar.Heading>
                <Navbar.Divider />
                <AnchorButton
                    className="bp3-minimal"
                    icon={LinkedIn}
                    href="https://www.linkedin.com/in/joshua-williams-452018bb/"
                    target="_blank"
                />
                <AnchorButton
                    className="bp3-minimal"
                    icon={Github}
                    href="https://github.com/jawsh"
                    target="_blank"
                />
                <Navbar.Divider />
            </Navbar.Group>
            <Navbar.Group align={Alignment.RIGHT}>
                <Navbar.Heading>
                    <code>jawsh.dev</code>
                </Navbar.Heading>
            </Navbar.Group>
        </Navbar>
    );
};

export default Navigation;
