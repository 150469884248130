import React from "react";

import "./App.scss";

import Typer from "./components/Typer";
import Showcase from "./components/Showcase";
import Navigation from "./components/Navigation";
import Footer from "./components/Footer";

function App() {
    return (
        <div className="App">
            <Navigation />
            <header className="App-header">
                <Typer />
            </header>
            <Showcase />
            <Footer />
        </div>
    );
}

export default App;
